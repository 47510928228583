import {useLogto} from "@logto/react";
import {authStore} from "../store/authStore.tsx";
import {useEffect} from "react";

export const useGetAccessToken = () => {
    const { isAuthenticated, getAccessToken} = useLogto();
    const { accessToken, setAccessToken } = authStore.getState();

    useEffect(() => {
        if (isAuthenticated && accessToken === '') {
            getAccessToken(import.meta.env.VITE_LOGTO_API_ID).then(resp => {
                setAccessToken(resp || '');
            });
        }
        console.log(accessToken);
    }, [accessToken, getAccessToken, isAuthenticated, setAccessToken]);
    return accessToken
}