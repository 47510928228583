import './SettingsViewSection.scss';
import {CanaiaFormEmpty} from "../../atoms/CanaiaFormEmpty/CanaiaFormEmpty.tsx";
import {CanaiaInput} from "../../atoms/CanaiaInput/CanaiaInput.tsx";
import {useGetAccessToken} from "../../../hooks/useGetAccessToken.tsx";
import useGetPageId from "../../../hooks/useGetPageId.tsx";
import {useEffect, useState} from "react";
import {UpdateAssistant, UseDeleteAssistant, UseFetchOneAssistant, UseUpdateAssistant} from "../../../api/ApiCalls.tsx";
import {Loading} from "@carbon/react";
import {CanaiaButton} from "../../atoms/CanaiaButton/CanaiaButton.tsx";
import {Save} from "@carbon/icons-react";
import CanaiaDangerModal from "../CanaiaDangerModal/CanaiaDangerModal.tsx";
import {useNavigate} from "react-router-dom";

export const SettingsViewSection = () => {
    const token = useGetAccessToken();
    const pageId = useGetPageId();

    const [name, setInputName] = useState('');
    const [deleteName, setDeleteName] = useState('');
    const [message, setMessage] = useState('');
    const [messageModal, setMessageModal] = useState('');
    const [isModalOpen, setModalOpen] = useState(false);

    const mutation = UseUpdateAssistant(token, pageId);
    const updatedMutation = UseFetchOneAssistant(token, pageId);
    const deleteMutation = UseDeleteAssistant(token, pageId);
    const fetchAssistants = UseFetchOneAssistant(token, pageId);
    const navigate = useNavigate();

    const {data, isLoading, isError} = UseFetchOneAssistant(token, pageId);

    // Extraer propiedades del asistente
    useEffect(() => {

        // Solo actualiza el estado si los datos están disponibles
        if (data && data.llm_system_prompt) {
            setInputName(data.name);
        }

    }, [data]);

    // Manejo de carga y error
    if (isLoading) {
        return <Loading withOverlay={false}/>;
    }

    if (isError || !data) {
        return <div>Error loading assistant data.</div>;
    }

    const updateName = () => {

        if (!name.trim()) {
            setMessage('The assistant name cannot be empty.');  // Mensaje de error
            setTimeout(() => setMessage(''), 3000);  // Limpiar el mensaje después de 3 segundos
            return; // Detener la ejecución si el campo está vacío
        }

        // Objeto que almacena los datos del asistente
        const dataAssistant: UpdateAssistant = {
            "name": name,
            "type": data.type,
            "transcriber_type": data.transcriber_type,
            "transcriber_endpoint_ms": data.transcriber_endpoint_ms,
            "voice_type": data.voice_type,
            "llm_type": data.llm_type,
            "llm_system_prompt": data.llm_system_prompt,
            "llm_temperature": data.llm_temperature
        }

        mutation.mutate(dataAssistant, {
            onSuccess: () => {
                updatedMutation.refetch().then(resp => resp);
                setMessage('Assistant updated successfully!');  // Mensaje de éxito
                setTimeout(() => setMessage(''), 3000);  // Limpiar el mensaje después de 3 segundos
            },
            onError: () => {
                setMessage('Error updating assistant.');  // Mensaje de error
                setTimeout(() => setMessage(''), 3000);  // Limpiar el mensaje después de 3 segundos
            }
        })
    }

    const handleDelete = () => {

        console.log('asd')

        if (deleteName.trim() !== name) {
            setMessageModal('You must type the assistant name to confirm deletion.'); // Mensaje de error
            setTimeout(() => setMessageModal(''), 3000); // Limpiar el mensaje después de 3 segundos
            return;
        }

        deleteMutation.mutate(undefined, { // Asumiendo que no necesitas pasar datos adicionales
            onSuccess: () => {
                setMessage('Assistant deleted successfully!'); // Mensaje de éxito
                setTimeout(() => {
                    setMessage('');
                    // Aquí puedes redirigir al usuario o realizar otra acción
                    fetchAssistants.refetch().then(resp => resp)
                    navigate('/assistants');
                }, 2000);
                setModalOpen(false); // Cerrar el modal
            },
            onError: () => {
                setMessage('Error deleting assistant.'); // Mensaje de error
                setTimeout(() => setMessage(''), 2000); // Limpiar el mensaje después de 3 segundos
            }
        });
    };

    const handleCancel = () => {
        setModalOpen(false); // Cerrar el modal
    };

    const handleModal = () => {
        setModalOpen(true)
    }

    return (
        <div className={'metrics-card assistant-configuration assistant-settings'}>
            <h4>Settings</h4>
            <CanaiaFormEmpty>
                <CanaiaInput
                    id='name'
                    label="Edit name"
                    disabled={false}
                    onChange={(e) => setInputName(e.target.value)}
                    value={name}
                    type="text"
                />
            </CanaiaFormEmpty>
            {message &&
                <h4 style={{textAlign: 'center', marginBottom: '2rem'}}
                    className="form-message">{message}
                </h4>} {/* Mostrar el mensaje */}
            <div className="form-footer">
                <CanaiaButton
                    onClick={updateName}
                    width="100px"
                    kind="secondary"
                    disabled={mutation.isPending} // Deshabilitar mientras está salvando
                >
                    <Save size={24}/>
                    {mutation.isPending ? 'Saving...' : 'Save'}
                </CanaiaButton>
            </div>
            <p>Danger Zone</p>
            <hr/>
            <CanaiaButton kind={'danger'} onClick={handleModal}>
                Delete Assistant
            </CanaiaButton>
            <CanaiaDangerModal
                open={isModalOpen}
                onClose={handleCancel}
                onPrimaryButtonClick={handleDelete}
                onSecondaryButtonClick={handleCancel}
            >
                <h2>Delete assistant!</h2>
                <p>This assistant is part of a CanAia pack. If you delete this assistant, you will delete the entire
                    content pack including related dashboards and reports. Type name assistant to delete.</p>
                {messageModal &&
                    <h5 style={{textAlign: 'center', margin: '1rem 0', color:'rgb(206, 0, 21)'}}
                        className="form-message">{messageModal}
                    </h5>} {/* Mostrar el mensaje */}

                <CanaiaInput
                    id='nameTodelete'
                    label=""
                    disabled={false}
                    onChange={(e) => setDeleteName(e.target.value)}
                    value={deleteName}
                    type="text"
                />
            </CanaiaDangerModal>

        </div>
    )
}