import {Layer, Loading, Tab, TabList, TabPanel, TabPanels, Tabs} from "@carbon/react";
import {CanaiaFormEmpty} from "../../../atoms/CanaiaFormEmpty/CanaiaFormEmpty";
import '../FormsConfigurations.scss';
import {CanaiaButton} from "../../../atoms/CanaiaButton/CanaiaButton";
import {Save} from "@carbon/icons-react";
import {CanaiaSelect} from "../../../atoms/CanaiaSelect/CanaiaSelect";
import {UpdateAssistant, UseFetchOneAssistant, UseUpdateAssistant} from "../../../../api/ApiCalls.tsx";
import {useGetAccessToken} from "../../../../hooks/useGetAccessToken.tsx";
import useGetPageId from "../../../../hooks/useGetPageId.tsx";
import {CanaiaInput} from "../../../atoms/CanaiaInput/CanaiaInput.tsx";
import {CanaiaSlider} from "../../../atoms/CanaiaSlider/CanaiaSlider.tsx";
import {useEffect, useState} from "react";
import {CanaiaTitleWithTooltip} from "../../../atoms/CanaiaTitleWithTooltip/CanaiaTitleWithTooltip.tsx";

export const FormPhoneConfig = () => {
    const token = useGetAccessToken();
    const pageId = useGetPageId();

    const [selectIdValue, setSelectIdValue] = useState<string>('');
    const [sliderValue, setSliderValue] = useState(0.001);
    const [promptValue, setInputPrompt] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [message, setMessage] = useState('');  // Estado para el mensaje

    const mutation = UseUpdateAssistant(token, pageId);
    const updatedMutation = UseFetchOneAssistant(token, pageId);

    const {data, isLoading, isError} = UseFetchOneAssistant(token, pageId);

    const selectOptions = {
        anthropic_3_haiku: "anthropic:claude-3-haiku",
        anthropic_3_sonnet: "anthropic:claude-3-sonnet",
        anthropic_3_opus: "anthropic:claude-3-opus",
        anthropic_35_sonnet: "anthropic:claude-3.5-sonnet",
        openai_gpt_35_turbo: "openai:gpt-3.5-turbo",
        openai_gpt_4_turbo: "openai:gpt-4-turbo",
        openai_gpt_4o: "openai:gpt-4o",
        openai_gpt_4o_mini: "openai:gpt-4o-mini",
    };

    // Extraer propiedades del asistente
    useEffect(() => {

        // Solo actualiza el estado si los datos están disponibles

        if(data && data.id) {
            setSelectIdValue( data.id.toString() )
        }

        if (data && data.llm_temperature) {
            setSliderValue(data.llm_temperature > 0 ? data.llm_temperature : 0.001);
        }

        if (data && data.llm_system_prompt) {
            setInputPrompt(data.llm_system_prompt);
        }

        if (data && data.llm_type) {
            setSelectedOption(data.llm_type);
        }

    }, [data]); // Dependencia para que se ejecute al cambiar llm_temperature

    // Manejo de carga y error
    if (isLoading) {
        return <Loading withOverlay={false}/>;
    }

    if (isError || !data) {
        return <div>Error loading assistant data.</div>;
    }

    // Maneja el cambio de opción en el select
    const handleSelectChange = (value: string) => {
        setSelectedOption(value);
    };

    // Maneja el cambio de opción en el slider
    const handleSliderChange = (value: number) => {
        setSliderValue(value);
    };

    const updateAssistant = () => {

        //Ajuste de Bug visual de Slider
        if(sliderValue === 0.001) {
            setSliderValue(0)
        }

        if (!promptValue.trim()) {
            setMessage('The system prompt cannot be empty.');  // Mensaje de error
            setTimeout(() => setMessage(''), 3000);  // Limpiar el mensaje después de 3 segundos
            return; // Detener la ejecución si el campo está vacío
        }

        // Objeto que almacena los datos del asistente
        const dataAssistant: UpdateAssistant = {
            "name": data.name,
            "type": data.type,
            "transcriber_type": data.transcriber_type,
            "transcriber_endpoint_ms": data.transcriber_endpoint_ms,
            "voice_type": data.voice_type,
            "llm_type": selectedOption,
            "llm_system_prompt": promptValue,
            "llm_temperature": sliderValue
        }

        mutation.mutate(dataAssistant, {
            onSuccess: () => {
                updatedMutation.refetch().then(resp => resp);
                setMessage('Assistant updated successfully!');  // Mensaje de éxito
                setTimeout(() => setMessage(''), 3000);  // Limpiar el mensaje después de 3 segundos
            },
            onError: () => {
                setMessage('Error updating assistant.');  // Mensaje de error
                setTimeout(() => setMessage(''), 3000);  // Limpiar el mensaje después de 3 segundos
            }
        })
    }

    return (
        <div className="metrics-card assistant-configuration">
            <Tabs>
                <TabList aria-label="List of tabs" contained fullWidth>
                    <Tab>Details</Tab>
                    <Tab>Transcriber</Tab>
                    <Tab>Voice</Tab>
                    <Tab>Advanced</Tab>
                </TabList>
                <CanaiaFormEmpty>
                    <TabPanels>
                        <TabPanel key="details">
                            <Layer>
                                <CanaiaSelect
                                    id={selectIdValue}
                                    options={selectOptions}
                                    value={selectedOption}   // Le pasamos el valor seleccionado
                                    onChange={handleSelectChange}
                                    labelText={'System Prompt'}
                                />
                                <CanaiaInput
                                    id='transcriber_endpoint'
                                    label="Transcriber Endpoint (ms)"
                                    disabled={false}
                                    onChange={(e) => setInputPrompt(e.target.value)}
                                    value={promptValue}
                                    type="text"
                                />
                                <CanaiaTitleWithTooltip
                                    title={"Behavior"}
                                    tooltipText={'Select between "formal and creative" for your virtual assistant\'s personality'}
                                />
                                <CanaiaSlider
                                    ariaLabelInput="Slider de ejemplo"
                                    disabled={false}
                                    invalidText="Valor inválido"
                                    labelText="Slider de ejemplo"
                                    max={1}
                                    min={0}
                                    step={0.01}
                                    stepMultiplier={1}
                                    unstable_ariaLabelInputUpper="Slider de ejemplo (superior)"
                                    value={sliderValue}
                                    warnText="Valor no válido"
                                    onChangeValue={handleSliderChange}
                                />
                            </Layer>
                        </TabPanel>
                        <TabPanel key={'transcriber'}>
                            <Layer>
                                <h3>Transcriber</h3>
                            </Layer>
                        </TabPanel>
                        <TabPanel key={'voice'}>
                            <Layer>
                                <h3>Voice</h3>
                            </Layer>
                        </TabPanel>
                        <TabPanel key={'advanced'}>
                            <Layer>
                                <h3>Advanced</h3>
                            </Layer>
                        </TabPanel>
                    </TabPanels>
                </CanaiaFormEmpty>
                {message && <h4 style={{textAlign: 'center', marginBottom:'2rem'}} className="form-message">{message}</h4>}  {/* Mostrar el mensaje */}
                <div className="form-footer">
                    <CanaiaButton
                        onClick={updateAssistant}
                        width="100px"
                        kind="secondary"
                        disabled={mutation.isPending} // Deshabilitar mientras está salvando
                    >
                        <Save size={24}/>
                        {mutation.isPending ? 'Saving...' : 'Save'}
                    </CanaiaButton>
                </div>
            </Tabs>
        </div>
    );
};

export default FormPhoneConfig;
