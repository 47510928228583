import React from "react";
import './CanaiaFormStatusSuccess.scss'

interface CanaiaFormStatusSuccessProps {
    text: string;
}

export const CanaiaFormStatusSucces: React.FC<CanaiaFormStatusSuccessProps> = ({
    text
}) => {
    return (
        <div className="canaia-status-success">
            {text}
        </div>
    )
}