import {useEffect} from "react";
import {UseFetchOneAssistant} from "../../../api/ApiCalls.tsx";
import {authStore} from "../../../store/authStore.tsx";
import {InlineLoading} from "@carbon/react";
import {useNavigate} from "react-router-dom";
import './MetricsHeader.scss'
import useGetPageId from "../../../hooks/useGetPageId.tsx";

export const MetricsHeader = () => {

    const {accessToken} = authStore();
    const idPage = useGetPageId();
    const {data, isPending, isError} = UseFetchOneAssistant(accessToken, idPage);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    if (isPending || !data) {
        return <InlineLoading></InlineLoading>
    }

    if (isError && !data) {
        setTimeout(() => {
            navigate("/assistants")
        }, 2000);
        return <p>Hubo un Error</p>
    }

    return (
        <>
            {
                data.type && data.name &&
                <>
                    <div className={'metric-type'}>{data.type}</div>
                    <div className={'metric-name'}>{data.name}</div>
                </>
            }
        </>
    )
}
