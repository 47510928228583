import logo from './../../../../assets/images/canaia_icon.svg';
import {CanaiaButton} from "../../../../ui/atoms/CanaiaButton/CanaiaButton.tsx";
import {Add} from "@carbon/icons-react";
import {useNavigate} from "react-router-dom";

export const NoAssistantsView = () => {

    const navigation = useNavigate();

    return (
        <div className={"no-assistant-view d-flex"}>
            <div className="icon">
                <img src={logo} alt=""/>
            </div>
            <div className={"message"}>
                <p style={{ fontSize:'2rem', marginBottom:'30px' }} ><b>Oops!</b></p>
                <p style={{ fontSize:'1.8rem' }}><b>No hay ningún Asistente.</b></p>
                <p style={{ fontSize:'1.5rem', marginBottom:'30px'  }}>Crea un asistente para continuar</p>
            </div>
            <div>
                <CanaiaButton kind={"secondary"} width={'300px'} onClick={()=> {navigation('/new-assistant')} }>
                    <Add></Add>Crea un Asistente
                </CanaiaButton>
            </div>
        </div>
    )
}