import React from "react";
import './ChannelsViewSection.scss';

interface ChannelsViewSectionProps {}

export const ChannelsViewSection: React.FC<ChannelsViewSectionProps> = () =>{
    return(
        <div className="channels-view-section-wapper">
            Sección Channels
        </div>
    )
}