import React, {useState} from "react";
import {Column, Content, Grid} from "@carbon/react";
import {AssistantHeader} from "../../../ui/modules/AssistantHeader/AssistantHeader";
import {CanaiaLateralMenu} from "../../../ui/components/CanaiaLateralMenu/CanaiaLateralMenu.tsx";
import {MetricsHeader} from "../../../ui/components/MetricsHeader/MetricsHeader.tsx";
// Se importan las vistas de cada sección
import {MetricsViewSection} from "../../../ui/components/MetricsViewSection/MetricsViewSection.tsx";
import {EventsViewSection} from "../../../ui/components/EventsViewSection/EventsViewSection.tsx";
import {AssistantViewSection} from "../../../ui/components/AssistantViewSection/AssistantViewSection.tsx";
import {PlaygroundViewSection} from "../../../ui/components/PlaygroundViewSection/PlaygroundViewSection.tsx";
import {ChannelsViewSection} from "../../../ui/components/ChannelsViewSection/ChannelsViewSection.tsx";
import {IntegrationsViewSection} from "../../../ui/components/IntegrationsViewSection/IntegrationsViewSection.tsx";
import {VersionsViewSection} from "../../../ui/components/VersionsViewSection/VersionsViewSection.tsx";
import {SettingsViewSection} from "../../../ui/components/SettingsViewSection/SettingsViewSection.tsx";
// Se importan las opciones del menu lateral
import {MenuOption} from "../../../ui/components/CanaiaLateralMenu/CanaiaLateralMenu.tsx";
import useGetPageId from "../../../hooks/useGetPageId.tsx";

interface Props {}

export const MetricsLayout: React.FC<Props> = () => {
    const pageId = useGetPageId();
    const [selectedOption, setSelectedOption] = useState<MenuOption>("Metrics");

    const handleMenuOption = (option: MenuOption) => {
        setSelectedOption(option);
    }
    //console.log("Opción seleccionada en MetricsLayout:", selectedOption);

    // Objeto que mapea las opciones del menú lateral a sus respectivos componentes de vista.
    const sections: Record<MenuOption, JSX.Element> = {
        Metrics: <MetricsViewSection/>,
        Events: <EventsViewSection />,
        Assistant: <AssistantViewSection />,
        Playground: <PlaygroundViewSection />,
        Channels: <ChannelsViewSection />,
        Integrations: <IntegrationsViewSection />,
        Versions: <VersionsViewSection />,
        Settings: <SettingsViewSection />,
    };
    return (
        <>
            <AssistantHeader page={'blue-header'}></AssistantHeader>
            <Content>
                <Grid className={'app-wrapper app__metrics'} fullWidth={true}>
                    <Column sm={4} md={{span:8, offset:0}} lg={{span:16, offset:0}} className="metrics-page__banner" style={{paddingBottom:'4.8rem'}}>
                        <MetricsHeader></MetricsHeader>
                    </Column>
                </Grid>
                <Grid className={'app-wrapper app__metrics'} fullWidth={true}>
                    <Column sm={1} md={3} lg={3}>
                        <CanaiaLateralMenu onSelect={handleMenuOption} />
                    </Column>
                    <Column sm={3} md={5} lg={12}>
                        {
                            pageId &&
                            sections[selectedOption]
                        }
                    </Column>
                </Grid>
            </Content>
        </>
    )
}