import React from 'react';
import './CanaiaLineChart.scss';
import { LineChart,ScaleTypes,TickRotations} from '@carbon/charts-react';
import '@carbon/charts-react/styles.css';

interface CanaiaLinearChartProps {
    data: { date: string; value: number }[];
}

export const CanaiaLinearChart: React.FC<CanaiaLinearChartProps> = ({data}) => {
    const options= {
        height: '400px',
        axes: {
            left: {
                title: 'Messages',
                mapsTo: 'value',
            },
            bottom: {
                title: 'Date',
                scaleType: ScaleTypes.TIME, // Asegúrate de que esto es 'time' o un valor válido
                mapsTo: 'date',
                ticks: {
                    rotation: TickRotations.ALWAYS,
                },
            },
        },
    };

    return (
        <div className="canaia-linear-chart-wrapper" >
            <LineChart data={data} options={options} />
        </div>
    );
};
