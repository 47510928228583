import React from "react";
import {Loading} from "@carbon/react";
import './CanaiaLoaderCircle.scss'


interface CanaiaLoaderCircleProps {
    withOverlay?: boolean;
}

export const CanaiaLoaderCircle: React.FC<CanaiaLoaderCircleProps> = ({
    withOverlay
}) =>{
    return (
        <div className={`canaia-loader-circle-wrapper ${withOverlay ? 'overlay' : ''}`}>
            <Loading/>
        </div>
    )
}