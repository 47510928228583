import axios, {AxiosResponse} from 'axios';
import {useMutation, useQuery} from "@tanstack/react-query";
import apiClient from "./ApiClient.ts";

export interface Assistant {
    id?: number
    name: string
    type: string
    transcriber_type?: string
    transcriber_endpoint_ms?: number
    voice_type?: string
    llm_type?: string
    llm_system_prompt?: string
    llm_temperature?: number
}

export interface FetchData {
    items: [],
    page: number,
    pages: number,
    size: number,
    total: number
}

export interface UpdateAssistant {
    name?: string
    transcriber_type?: string
    transcriber_endpoint_ms?: number,
    voice_type?: string
    type?: string,
    llm_type?: string,
    llm_system_prompt?: string,
    llm_temperature?: number
}

export interface Messages {
    items: []
}

export interface ConversationsInfo {
    channel?: string,
    created_at: string,
    id: number
}

const QUERY_GET_ASSISTANT = ['GetAssistant'];
const QUERY_GET_ONE_ASSISTANT = ['GetOneAssistant'];
const QUERY_POST_ASSISTANT = ['PostAssistant'];
const QUERY_UPDATE_ASSISTANT = ['UpdateAssistant'];
const QUERY_DELETE_ASSISTANT = ['DeleteAssistant'];
const QUERY_GET_CHANNELS = ['FetchChannels'];
const QUERY_GET_CONVERSATIONS = ['FetchConversations'];
const QUERY_GET_CONVERSATIONS_INFO = ['FetchConversationsInfo'];
const QUERY_DELETE_CONVERSATION = ['DeleteAssistant'];
const QUERY_GET_MESSAGES = ['FetchMessages'];
const QUERY_GET_MESSAGES_STATS = ['FetchMessagesStats'];
const QUERY_GET_CONVERSATIONS_STATS = ['FetchConversationsStats'];

const fetchRequest = async (token: string, url: string): Promise<AxiosResponse> => {
    return await apiClient(token).get(url).then(resp => resp);
};

/*const fetchAssistants = async (token: string): Promise<AxiosResponse<FetchData, never>> => {
    return await apiClient(token).get<FetchData[], never>(`/api/user/assistants`).then(resp => resp);
}*/

/*const fetchOneAssistant = async (token: string, id: string | undefined): Promise<AxiosResponse<Assistant, never>> => {
    return await apiClient(token).get<Assistant, never>(`/api/assistants/${id}`).then(resp => resp);
}*/

const postAssistant = async (newData: Assistant, token: string | void) => {

    if (token) {
        try {
            return await apiClient(token).post(`/api/user/assistants`, newData); // Devolvemos la respuesta si es necesario
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw error; // Lanzamos el error de Axios
            }
            throw new Error("An unexpected error occurred");
        }
    } else {
        throw new Error("Token is missing");
    }
}

const updateAssistant = async (newData: UpdateAssistant, token: string, id: string | undefined) => {
    await apiClient(token).patch(`/api/assistants/${id}`, newData).then(resp => resp)
}

const deleteAssistant = async (token: string, id: string | undefined) => {
    return await apiClient(token).delete(`/api/assistants/${id}`).then(resp => resp);
}

const deleteConversation = async (token: string, id: string) => {
    return await apiClient(token).delete(`/api/conversations/${id}`).then(resp => resp);
}

const fetchChannels = async (token: string, id: string | undefined): Promise<AxiosResponse<FetchData>> => {
    return await apiClient(token).get<Assistant, never>(`/api/assistants/${id}/channels`).then(resp => resp);
}

/*const fetchConversations = async (token: string, id: string | undefined): Promise<AxiosResponse<FetchData>> => {
    return await apiClient(token).get<Assistant, never>(`/api/assistants/${id}/conversations`).then(resp => resp).catch(error => error)
}*/

const fetchConversationsInfo = async (token: string, id: string | undefined): Promise<AxiosResponse<ConversationsInfo>> => {
    return await apiClient(token).get(`/api/conversations/${id}`).then(resp => resp);
}

const fetchMessages = async (token: string, id: string | undefined): Promise<AxiosResponse<FetchData>> => {
    return await apiClient(token).get<FetchData>(`/api/conversations/${id}/messages`).then(resp => resp);
}

const fetchMessagesStats = async (token: string, id: string | undefined, from: string, to: string): Promise<AxiosResponse<Messages, never>> => {
    return await apiClient(token).get<Messages, never>(`/api/assistants/${id}/messages/stats?from=${from}&to=${to}`).then(resp => resp);
}

const fetchConversationsStats = async (token: string, id: string | undefined, from: string, to: string): Promise<AxiosResponse<Messages, never>> => {
    return await apiClient(token).get<Messages, never>(`/api/assistants/${id}/conversations/stats?from=${from}&to=${to}`).then(resp => resp);
}

// Fetch All assistants
export const UseFetchAssistants = (token: string) => {

    return useQuery({
        queryKey: [QUERY_GET_ASSISTANT, token],
        queryFn: async () => {
            try {
                return await fetchRequest(token, `/api/user/assistants`);
            } catch (error) {
                throw new Error("Error fetching assistants");
            }
        },
        enabled: !!token,
    });

};

// Fetch One assistant
export const UseFetchOneAssistant = (token: string, id: string | undefined) => {

    return useQuery({
        queryKey: [QUERY_GET_ONE_ASSISTANT, token, id],
        queryFn: async ():Promise<Assistant | never> => {
            try {
                const {data} =  await fetchRequest(token, `/api/assistants/${id}`);
                return data
            } catch (error) {
                throw new Error("Error fetching assistants");
            }
        },
        enabled: !!token && !!id,
    });

}

export const UsePostAssistant = (token: string) => {

    return useMutation({
        mutationFn: (newData: Assistant) => postAssistant(newData, token),
        mutationKey: QUERY_POST_ASSISTANT,
        retry: 3
    });

}

// Update Assistant
export const UseUpdateAssistant = (token: string, id: string | undefined) => {

    return useMutation({
        mutationKey: QUERY_UPDATE_ASSISTANT,
        mutationFn: (updateData:UpdateAssistant) => updateAssistant(updateData, token, id),
        retry: 3,
    });

}

// Delete Assistant
export const UseDeleteAssistant = (token: string, id: string | undefined) => {

    return useMutation({
        mutationKey: QUERY_DELETE_ASSISTANT,
        mutationFn: () => deleteAssistant(token, id),
        retry: 3
    })

}

export const UseDeleteConversation = (token: string) => {

    return useMutation({
        mutationKey: QUERY_DELETE_CONVERSATION,
        mutationFn: (id: string) => deleteConversation(token, id),
        retry: 3
    })

}

export const UseFetchChannels = (token: string, id: string | undefined) => {

    return useQuery({
        queryKey: QUERY_GET_CHANNELS,
        queryFn: async () => {
            const {data} = await fetchChannels(token, id);
            return data;
        },
        enabled: !!token && !!id,
        staleTime: 0,
        refetchOnWindowFocus: true, // Refresca datos al enfocar la ventana
        refetchOnMount: true, // Refresca datos al montar el componente

    })

}

export const UseFetchConversations = (token: string, id: string | undefined) => {

    return useQuery({
        queryKey: [QUERY_GET_CONVERSATIONS, token],
        queryFn: async () => {
            try {
                return await fetchRequest(token, `/api/assistants/${id}/conversations`);
            } catch (error) {
                throw new Error("Error fetching assistants");
            }
        },
        staleTime: 1000,
        enabled: !!token && !!id,
    });

}

export const UseFetchConversationStats = (token: string, id: string | undefined, from:string, to:string) => {
    return useQuery({
        queryKey: [QUERY_GET_CONVERSATIONS_STATS, token, id, from, to],
        queryFn: async () => {
            const {data} = await fetchConversationsStats(token, id, from, to);
            return data;
        },
        staleTime: 1000,
        enabled: !!token && !!id && !!from && !!to
    })
}

export const UseFetchMessages = (token: string, id: string | undefined) => {

    return useQuery({
        queryKey: [QUERY_GET_MESSAGES, token, id],
        queryFn: async () => {
            const {data} = await fetchMessages(token, id);
            return {data};
        },
        enabled: !!token && !!id,
    })
}

export const UseFetchMessagesStats = (token: string, id: string | undefined, from:string, to:string) => {
    return useQuery({
        queryKey: [QUERY_GET_MESSAGES_STATS, token, id, from, to],
        queryFn: async () => {
            const {data} = await fetchMessagesStats(token, id, from, to);
            return data;
        },
        staleTime: 1000,
        enabled: !!token && !!id && !!from && !!to
    })
}

export const UseFetchConversationsInfo = (token: string, id: string | undefined) => {
    return useQuery({
        queryKey: [QUERY_GET_CONVERSATIONS_INFO, token, id],
        queryFn: async () => {
            const {data} = await fetchConversationsInfo(token, id);
            return data
        },
        staleTime: 100,
        enabled: !!token && !!id,
    })
}