import React from "react";
import './CanaiaSettingsPhoneChannelSection.scss';
import {CanaiaTitleSimple} from "../../atoms/CanaiaTitleSimple/CanaiaTitleSimple.tsx";
import {CanaiaSubtitleSimple} from "../../atoms/CanaiaSubtitleSimple/CanaiaSubtitleSimple.tsx";
import {CanaiaTextWithIconColor} from "../../atoms/CanaiaTextWithIconColor/CanaiaTextWithIconColor.tsx";
import {CanaiaPogressBar} from "../../atoms/CanaiaPogressBar/CanaiaPogressBar.tsx";
import FormPhoneConfig from "../../modules/FormsConfigurations/FormPhoneConfig/FormPhoneConfig.tsx";
//Import iconos response time
import IconGreen from "../../../assets/images/robot-green.svg";
import IconYellow from "../../../assets/images/robot-yellow.svg";
import IconRed from "../../../assets/images/robot-red.svg";
import IconClock from "../../../assets/images/clock.svg";


interface CanaiaSettingsPhoneChannelSection {}

export const CanaiaSettingsPhoneChannelSection:React.FC<CanaiaSettingsPhoneChannelSection>= () =>{


    //Definición de las claves de las opciones
    const optionsTimeResponse = {
        Excellent:{icon:IconGreen, color:'green'},
        Good:{icon:IconYellow, color:'yellow'},
        Bad:{icon:IconRed, color:'red'},
    } as const;
    // Obtiene las claves de los objetos
    type OptionsQuality = keyof typeof optionsTimeResponse;

    const showOptionQuality = (value: number): {icon:string; color:string; key: OptionsQuality } | undefined => {
        if (value <= 1) {
            return {...optionsTimeResponse.Excellent,key:'Excellent'};
        }else if (value > 1 && value < 3) {
            return {...optionsTimeResponse.Good,key: 'Good'};
        }else if (value >= 3    ){
            return {...optionsTimeResponse.Bad,key:'Bad'}
        }
        return undefined;
    }

    const optionSelected = showOptionQuality(1);
    return (
        <div className="canaia-setting-channel-section">
            <div className="response-time-wrapper">
                <div className="information-wrapper">
                    <div className="titles-wrapper">
                        <CanaiaTitleSimple title={'Response time'}/>
                        <CanaiaSubtitleSimple
                            title={'The time it takes for the assistant to reply after the user stops talking.'}/>
                    </div>
                    <div className="quality-score-wrapper">
                        <CanaiaSubtitleSimple
                            title={'Quality score'}/>
                        <div className="quality-score-options">
                            {optionSelected && (
                                <CanaiaTextWithIconColor
                                    icon={optionSelected.icon}
                                    text={optionSelected.key}
                                    color={optionSelected.color}/>
                            )}
                        </div>
                    </div>
                </div>
                <div className="progress-bar-wrapper">
                    <CanaiaTextWithIconColor icon={IconClock} text="-1450ms" color={'green-b'}/>
                    <CanaiaPogressBar maxValue={100} className="test-class" value={20} size='big' type='inline' helperText="HelperText" hideLabel={true} status='active' label='Progress'/>
                </div>
                <div className="form-config-wrapper">
                    {/*//Aquí va formulario*/}
                    <FormPhoneConfig/>
                </div>
            </div>
        </div>
    );
}