import {MainLayout} from "../../../../ui/layout/MainLayout.tsx";
import {CanaiaButton} from "../../../../ui/atoms/CanaiaButton/CanaiaButton.tsx";
import {UsePostAssistant} from "../../../../api/ApiCalls.tsx";
import {useLogto} from "@logto/react";
import {useEffect, useState} from "react";
import {CanaiaInput} from "../../../../ui/atoms/CanaiaInput/CanaiaInput.tsx"
import {CanaiaTextArea} from "../../../../ui/atoms/CanaiaTextArea/CanaiaTextArea.tsx";
import {CanaiaSlider} from "../../../../ui/atoms/CanaiaSlider/CanaiaSlider.tsx";
import {CanaiaFormEmpty} from "../../../../ui/atoms/CanaiaFormEmpty/CanaiaFormEmpty.tsx";
import {CanaiaTitleWithTooltip} from "../../../../ui/atoms/CanaiaTitleWithTooltip/CanaiaTitleWithTooltip.tsx";
import {CanaiaSubtitleSimple} from "../../../../ui/atoms/CanaiaSubtitleSimple/CanaiaSubtitleSimple.tsx";
import {CanaiaSetOfButtons} from "../../../../ui/atoms/CanaiaSetOfButtons/CanaiaSetOfButtons.tsx";
import {CanaiaTitleWithImage} from "../../../../ui/atoms/CanaiaTitleWithImage/CanaiaTitleWithImage.tsx";
import {CanaiaFormSendingStatus} from "../../../../ui/atoms/CanaiaFormSendingStatus/CanaiaFormSendingStatus.tsx";
import {CanaiaFormStatusSucces} from "../../../../ui/atoms/CanaiaFormStatusSuccess/CanaiaFormStatusSuccess.tsx";
import {CanaiaFormErrorStatus} from "../../../../ui/atoms/CanaiaFormErrorStatus/CanaiaFormErrorStatus.tsx";
import CanaiaIconLogo from '../../../../assets/images/canaia_icon.svg'
import {Add} from "@carbon/icons-react";
import {useNavigate} from "react-router-dom";

export const NewAssistant = () => {

    // Importar el hook personalizado useLogto para manejar la autenticación
    const { getAccessToken, isAuthenticated } = useLogto();
    // Declarar un estado para almacenar el token de acceso
    const [token, setToken] = useState('');
    console.log(token);
    // Utilizar el hook personalizado UsePostAssistant, pasándole el token para crear un asistente
    const mutateAssistant = UsePostAssistant(token);

    const[isLoading, setIsLoading] = useState(false);
    const[isSuccess, setIsSuccess] = useState(false);
    const[isError, setIsError] = useState(false);

    // Este hook se ejecuta cada vez que isAuthenticated o getAccessToken cambian
    useEffect(() => {
        // Verificar si el usuario está autenticado
        if (isAuthenticated) {
            // Obtener el token de acceso utilizando el ID de API de Logto
            getAccessToken(import.meta.env.VITE_LOGTO_API_ID).then(resp => {
                // Actualizar el estado del token con el valor obtenido o una cadena vacía
                setToken(resp || '');
            });
        }
    }, [getAccessToken, isAuthenticated, token]);

    // Definición de los estados de componentes
    const [inputValue, setInputValue] = useState(''); // Estado para almacenar el valor de un input de texto
    const [textareaValue, setTextareaValue] = useState(''); // Estado para almacenar el valor de un textarea
    const [sliderValue, setSliderValue] = useState(0); // Estado para almacenar el valor de un slider (rango entre 0 y 1)
    const [,setErrorMessage] = useState(''); // Estado para almacenar mensajes de error
    const navigate = useNavigate();

    // Función para manejar cambios en el valor del slider
    const handleSliderChange = (value: number) => {
        // Actualiza el estado del slider con el nuevo valor
        setSliderValue(value);
    };

    // Función para manejar el clic en el botón primario
    const handleButtonClick = (text: string) => {
        // Actualiza el estado del textarea con el texto proporcionado
        setTextareaValue(text);
    };

    const createAssistant = () => {

        if(!inputValue || !textareaValue) {
            alert("Please fill all fields.")
            setErrorMessage("Please fill all fields.");
            return;
        }

        // Objeto que almacena los datos del asistente
        const dataAssistant = {
            "name": inputValue,
            "type": "blank",
            "transcriber_type": "deepgram:nova-2-es",
            "transcriber_endpoint_ms": 0,
            "voice_type": "aws-polly:es-neural-lucia",
            "llm_type": "anthropic:claude-3-haiku",
            "llm_system_prompt": textareaValue,
            "llm_temperature": sliderValue
        }

        setIsLoading(true); // Activa el estado de carga
        // Llamada a la mutación para crear un asistente
        mutateAssistant.mutate(dataAssistant, {
            onSuccess: () => {
                // Se ejecuta cuando la mutación se completa exitosamente
                // Limpia los campos del formulario
                setIsSuccess(true);
                setInputValue('');
                setTextareaValue('');
                setSliderValue(0);
                setErrorMessage(''); // Resetea el estado de error
                setIsLoading(false); // Desactiva el estado de carga
            },
            onError: (mutationError) => {
                // Se ejecuta cuando ocurre un error durante la mutación
                console.error('Error details:', mutationError); // Imprime el error en la consola
                setErrorMessage(mutationError.message); // Establece el mensaje de error en el estado
                setIsLoading(false); // Desactiva el estado de carga
                setIsError(true);
            }
        });
    };

    // useEffect para manejar el temporizador del estado de éxito
    useEffect(() => {
        if (isSuccess) {
            const timer = setTimeout(() => {
                setIsSuccess(false); // Restablece el estado de éxito
            }, 1000); // Muestra por 3 segundos
            return () => {
                clearTimeout(timer);
                navigate('/assistants');
            }; // Limpia el temporizador al desmontar
        }
    }, [isSuccess, navigate]);

    // useEffect para manejar el temporizador del estado de error
    useEffect(() => {
        if (isError) {
            const timer = setTimeout(() => {
                setIsSuccess(false); // Restablece el estado de error
            }, 1000); // Muestra por 3 segundos

            return () => clearTimeout(timer); // Limpia el temporizador al desmontar
        }
    }, [isError]);

    return (
        <MainLayout>
            <CanaiaTitleWithImage
                title1={'Create a new'}
                title2={'Assistant'}
                image={CanaiaIconLogo}
                alt={"Logo Canaia"}
            />
            <h2 style={{textAlign: 'left', marginBottom: '3rem',fontSize:'1.75rem', fontWeight:'700', lineHeight:'2.43rem'}}>
                Tell us about your Assistant
            </h2>
            <CanaiaFormEmpty>
                {isError && (
                    <CanaiaFormErrorStatus text={'Error creating assistant.'} />
                )}
                {isLoading && ( // Renderiza el componente solo si isLoading es true
                    <CanaiaFormSendingStatus text={'Creating a new assistant...'} />
                )}
                {isSuccess && (
                    <CanaiaFormStatusSucces text={'Assistant created successfully!'} />
                )}
                <CanaiaInput
                    label={"Name"}
                    id={"name"}
                    type={"text"}
                    required={true}
                    placeholder={"The name of your assistant"}
                    disabled={false}
                    onChange={(e) => setInputValue(e.target.value)}
                    value={inputValue}
                />
                <CanaiaTitleWithTooltip
                    title={"Behavior"}
                    tooltipText={'Select between "formal and creative" for your virtual assistant\'s personality'}
                />
                <CanaiaSlider
                    ariaLabelInput="Slider de ejemplo"
                    disabled={false}
                    invalidText="Valor inválido"
                    labelText="Slider de ejemplo"
                    max={1}
                    min={0}
                    step={0.01}
                    stepMultiplier={1}
                    unstable_ariaLabelInputUpper="Slider de ejemplo (superior)"
                    value={sliderValue}
                    warnText="Valor no válido"
                    onChangeValue={handleSliderChange}
                />
                <CanaiaTitleWithTooltip
                    title={"Guidelines"}
                    tooltipText={'Tips for your smart assistant profile'}
                />
                <CanaiaSubtitleSimple
                    title={"Suggestions:"}
                />
                <CanaiaSetOfButtons
                    primaryTitle={"Bussines information"}
                    textPrimaryButton={"Bussines information"}
                    secondaryTitle={"Customer support"}
                    textSecondaryButton={"Customer support"}
                    onButtonClick={handleButtonClick}
                />
                <CanaiaTextArea
                    label={""}
                    id={"prompt"}
                    placeholder={"Type your prompt..."}
                    required={true}
                    value={textareaValue}
                    onChange={(e) => setTextareaValue(e.target.value)}
                />
                <CanaiaButton
                    kind={"secondary"}
                    onClick={createAssistant}
                    width={"175px"}
                    disabled={isLoading} // Desactiva el botón si está cargando
                >
                    <Add className={"white-color"}/>
                    Create Assistant
                </CanaiaButton>
            </CanaiaFormEmpty>
        </MainLayout>
    )
}

