import useGetPageId from "../../../hooks/useGetPageId.tsx";
import {UseFetchConversations} from "../../../api/ApiCalls.tsx";
import {authStore} from "../../../store/authStore.tsx";
import {Loading} from "@carbon/react";
import {CanaiaTable} from "../../atoms/CanaiaTable/CanaiaTable.tsx";
import {useEffect, useState} from "react";

interface ConversationType {
    id: string;
    bot?: string;
    channel: string;
    created_at: string;
}

export const ConversationsTable = () => {
    const pageId = useGetPageId();
    const { accessToken } = authStore();
    const { data, isLoading, isError } = UseFetchConversations(accessToken, pageId);
    const dataRefetch = UseFetchConversations(accessToken, pageId);
    const [conversations, setConversations] = useState<ConversationType[]>([]);

    useEffect(() => {
        if (data && data.status === 200) {
            setConversations(data.data.items); // Actualizamos el estado con los datos
        }
    }, [data]); // Se actualiza cuando data cambia

    const handleDelete = (id: string) => {
        setConversations(prevConversations =>
            prevConversations.filter(conversation => conversation.id.toString() !== id)
            // Filtra la conversación eliminada
        );
        dataRefetch.refetch().then(resp => resp);
        // Realiza un nuevo fetch de los datos
    };

    if (isLoading || data === undefined) {
        return (
            <div className="metrics-card">
                <Loading className={'table-loading'} withOverlay={false} />
            </div>
        );
    }

    if (isError) {
        return (
            <div className="metrics-card">
                <p>Hubo un error</p>
            </div>
        );
    }

    if (conversations.length === 0) { // Cambiado para usar conversations
        return (
            <div className="metrics-card">
                <p>No existen conversaciones</p>
            </div>
        );
    }

    return (
        <div className="metrics-card">
            <h4>Conversations</h4>
            <CanaiaTable dataRow={conversations} onDelete={handleDelete} />
        </div>
    );
};

export default ConversationsTable;
