import axios from 'axios';

const apiClient = (token:string) => axios.create({
    baseURL: import.meta.env.VITE_API_ENDPOINT,
    timeout: 1000,
    headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
})

export default apiClient;