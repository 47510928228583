import React from "react";
import {Column, Grid} from "@carbon/react";
import {AssistantHeader} from "../modules/AssistantHeader/AssistantHeader.tsx"


interface Props {
    children: React.ReactNode
}


export const MainLayout: React.FC<Props> = ({children}) => {

    return (
        <>
            <AssistantHeader page={'white-header'}></AssistantHeader>
            <main>
                <Grid className={'app-wrapper'} fullWidth={true}>
                    <Column sm={4} md={8} lg={16}>
                        {children}
                    </Column>
                </Grid>
            </main>
        </>
    )
}
