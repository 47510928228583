import {Layer, Tab, TabList, TabPanel, TabPanels, Tabs} from "@carbon/react";
import {CanaiaFormEmpty} from "../../../atoms/CanaiaFormEmpty/CanaiaFormEmpty.tsx";
import {CanaiaInput} from "../../../atoms/CanaiaInput/CanaiaInput.tsx";
import '../FormsConfigurations.scss';

const tabConfig = [
    {
        label: 'Details', content: [
            {id: 'address', label: 'Address'}
        ]
    },
    {
        label: 'Transcriber', content: [
            {id: 'proveedor', label: 'Proveedor'},
            {id: 'voz', label: 'Voz'},
            {id: 'velocidad', label: 'Velocidad de habla'}
        ]
    },
    {
        label: 'Voice', content: [
            {id: 'lenguaje', label: 'Lenguaje'}
        ]
    },
    {
        label: 'Advance', content: [
            {id: 'duración', label: 'Duración máxima llamada'},
            {id: 'umbral', label: 'Umbral de silencio'}
        ]
    }
];

export const FormTelegramConfig = () => {
    return (
        <div className="metrics-card assistant-configuration">
            <Tabs>
                <TabList aria-label="List of tabs" contained fullWidth>
                    {tabConfig.map((tab, index) => (
                        <Tab key={index}>{tab.label}</Tab>
                    ))}
                </TabList>
                <CanaiaFormEmpty>
                    <TabPanels>
                        {tabConfig.map((tab, index) => (
                            <TabPanel key={index}>
                                <Layer>
                                    {tab.content.map((input) => (
                                        <CanaiaInput key={input.id} id={input.id} label={input.label}/>
                                    ))}
                                </Layer>
                            </TabPanel>
                        ))}
                    </TabPanels>
                </CanaiaFormEmpty>
            </Tabs>
        </div>
    )
}

export default FormTelegramConfig;