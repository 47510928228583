import React from "react";
import './EventsViewSection.scss'

interface EventsViewSectionProps {}

export const EventsViewSection : React.FC<EventsViewSectionProps> = () => {
    return (
        <div className="events-view-section-wrapper">
            Sección de eventos
        </div>
    )
}