import React from "react";
import './VersionsViewSection.scss';

interface VersionsViewSectionProps {}

export const VersionsViewSection: React.FC<VersionsViewSectionProps> = () => {
    return (
        <div className="versions-view-section-wrapper">
            Sección  Versions
        </div>
    )
}