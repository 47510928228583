import {Loading, Modal} from "@carbon/react";
import {FetchData, UseFetchConversationsInfo, UseFetchMessages} from "../../../api/ApiCalls.tsx";
import {authStore} from "../../../store/authStore.tsx";
import {Message, Avatar} from '@chatscope/chat-ui-kit-react';
import icon from '../../../assets/images/canaia_icon.svg'
import "@chatscope/chat-ui-kit-styles/themes/default/main.scss";
import './ConversationsModal.scss'

interface Props {
    open: boolean,
    onClick: () => void,
    conversationId: string
}

interface Item {
    created_at: string,
    is_user: boolean,
    message: string,
    rank: number
}

type MessageDirection = "incoming" | "outgoing" | 0 | 1;

type Position = "single" | "first" | "normal" | "last" | 0 | 1 | 2 | 3;

export const ConversationsModal = ({open, onClick, conversationId}: Props) => {

    const {accessToken} = authStore();

    const {data, isFetching} = UseFetchMessages(accessToken, conversationId);
    const dataConversation: { data: FetchData } | undefined = data

    const data1 = UseFetchConversationsInfo(accessToken, conversationId);
    const dataHeader = data1.data;

    const MessageBubble = (msg: string, sender: string, direction: MessageDirection, position: Position, avatar: boolean, id: string) => {
        return (
            <Message key={id} model={{message: msg, sender: sender, direction: direction, position: position}}>
                {
                    avatar
                        ? <Avatar key={msg} src={icon} name={"Canaia Assist"}/>
                        : ''
                }
            </Message>
        )
    }

    const MessagesArray: JSX.Element[] | undefined = dataConversation?.data.items.sort((a: Item, b: Item) => a.rank - b.rank).map((item: Item) => {
        if (item.is_user) {
            return MessageBubble(item.message, 'User', 'outgoing', 'normal', false, item.created_at)
        }
        return MessageBubble(item.message, 'Canaia', 'incoming', 'normal', true, item.created_at)
    })

    return (
        <>
            <Modal
                className={'conversation-modal'}
                open={open}
                onRequestClose={onClick}
                passiveModal
                modalHeading="">
                {
                    isFetching ?
                        <Loading className={"conversation-loading"} withOverlay={false}/> :
                        <>
                            <div className={"header-title"}>
                                {
                                    dataHeader &&
                                    <>
                                        <div className="row conversation-header-wrapper">
                                            {Object.keys(dataHeader).map(key => (
                                                <div key={key} className={'col text-center conversation-header-cell desc'}>{key}</div>
                                            ))}
                                        </div>
                                        <div className="row conversation-header-wrapper">
                                            {Object.entries(dataHeader).map(([key, subject]) => (
                                                <div key={key} className={'col text-center conversation-header-cell title'}>{subject}</div>
                                            ))}
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="messages-wrapper">
                                {MessagesArray}
                            </div>
                        </>
                }
            </Modal>
        </>

    )
}