import {Route, Routes} from "react-router-dom";
import {Home} from "../pages/Home";
import {NewAssistant} from "../pages/Home/views/NewAssistant/NewAssistant.tsx";
import Pricing from "../pages/Pricing";
import {UseAuth} from "../hooks/useAuth.tsx";
import SignInCallback from "../pages/SignInCallback.tsx";
import {ViewAssistants} from "../pages/Home/views/ViewAssistants";
import {CatalogueAssistants} from "../pages/Home/views/CatalogueAssistants";
import {Metrics} from "../pages/Metrics";


export const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/sign-in-callback" element={<SignInCallback/>}/>
            <Route element={<UseAuth />}>
                <Route path={"pricing"} element={<Pricing />}></Route>
                <Route path={"new-assistant"} element={<NewAssistant />}></Route>
                <Route path={"choose-assistant"} element={<CatalogueAssistants />}></Route>
                <Route path={"assistants"} element={<ViewAssistants />}></Route>
                <Route path={"global/:pageId"} element={<Metrics />}></Route>
            </Route>
        </Routes>
    )
}
