import { MainLayout } from "../../../../ui/layout/MainLayout.tsx";
import { AssistantsGrid } from "./AssistantsGrid.tsx";
import { authStore } from "../../../../store/authStore.tsx";
import { UseFetchAssistants } from "../../../../api/ApiCalls.tsx";
import { Loading } from "@carbon/react";
import './ViewAssistants.scss';
import { NoAssistantsView } from "./NoAssistantsView.tsx";
import { useLogto } from "@logto/react";

export const ViewAssistants = () => {
    const { userName, accessToken } = authStore.getState();
    const { data, isLoading, isError, isFetching } = UseFetchAssistants(accessToken);
    const { signOut } = useLogto();


    // Si la data está cargando o es indefinida
    if (isLoading || isFetching) {
        return (
            <MainLayout>
                <Loading className="table-loading" withOverlay={false} />
            </MainLayout>
        );
    }

    // Si hay error y no hay data
    if (isError) {
        setTimeout(() => signOut(window.location.origin + "/"), 2000);
        return (
            <MainLayout>
                <p>Hubo un error</p>
            </MainLayout>
        );
    }

    // Validar la estructura de data antes de usarla
    if (!data || !data.data || !Array.isArray(data.data.items)) {
        return (
            <MainLayout>
                <p>Error: La estructura de datos no es válida.</p>
            </MainLayout>
        );
    }

    // Si no hay asistentes disponibles
    if (data.data.items.length === 0) {
        return (
            <MainLayout>
                <NoAssistantsView />
            </MainLayout>
        );
    }

    // Renderiza los asistentes si hay datos disponibles
    return (
        <MainLayout>
            <h2 style={{ textAlign: 'left', marginBottom: '3rem', fontSize: '1.5rem' }}>
                Welcome <span>{userName}</span>
            </h2>
            <AssistantsGrid data={data.data.items} />
        </MainLayout>
    );
};
