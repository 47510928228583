import React from "react";
import './PlaygroundViewSection.scss';

interface PlaygroundViewSectionProps {}

export const PlaygroundViewSection: React.FC<PlaygroundViewSectionProps> = () => {
    return (
        <div className="ṕlayground-view-section-wrapper">
            Sección Playground
        </div>
    )
}