import React from "react";
import './CanaiaFormSendingStatus.scss'
import {CanaiaLoaderCircle} from "../CanaiaLoaderCircle/CanaiaLoaderCircle.tsx";

interface CanaiaFormSendingStatusProps {
    text: string;
}

export const CanaiaFormSendingStatus: React.FC<CanaiaFormSendingStatusProps> = ({
    text
}) => {
    return (
        <div className="canaia-form-status-sending-wrapper">
            <div className="canaia-form-status-sending">
                <CanaiaLoaderCircle/>
                {text}
            </div>
        </div>
    )
}