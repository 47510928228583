import './App.css'
import {AppRoutes} from "./router/AppRoutes.tsx";
import {useEffect} from "react";
import {useLogto} from "@logto/react";
import {authStore} from "./store/authStore.tsx";
import {useGetAccessToken} from "./hooks/useGetAccessToken.tsx";

function App() {
    const {isAuthenticated, getIdTokenClaims, getAccessToken} = useLogto();
    const {setUserName, setAccessToken} = authStore.getState();
    useGetAccessToken();
    useEffect(() => {
        (async () => {
            if (isAuthenticated) {
                const claims = await getIdTokenClaims();
                setUserName(claims?.name || "");
                /*getAccessToken(import.meta.env.VITE_LOGTO_API_ID, '').then(resp => {
                    setAccessToken(resp || '');
                })*/
            }
        })();
    }, [getAccessToken, getIdTokenClaims, isAuthenticated, setAccessToken, setUserName]);

    /*console.log(accessToken);*/
    
    return (
        <>
            <AppRoutes/>
        </>
    )
}

export default App
