import React from "react";
import './CanaiaButtonWithIcon.scss';

interface CanaiaButtonWithIconProps {
    title: string;
    image: string;
    alt?: string;
    onClick?: () => void;
    className?: string;
}

export const CanaiaButtonWithIcon: React.FC<CanaiaButtonWithIconProps> = ({title,image,alt, onClick, className}) => {
    return (
        <div className={`canaia-button-with-image-wrapper ${className}`} onClick={onClick} >
            <div className="image-wrapper">
                <img src={image} alt={alt} />
            </div>
            <div className="text-wrapper">
                {title}
            </div>
        </div>
    );
}