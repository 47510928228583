import DataTable, {TableColumn} from "react-data-table-component";
import {useEffect, useState} from "react";
import './CanaiaTable.scss';
import {DocumentBlank, TrashCan} from "@carbon/icons-react";
import {UseDeleteConversation} from "../../../api/ApiCalls.tsx";
import {authStore} from "../../../store/authStore.tsx";
import {Modal} from "@carbon/react";
import {ConversationsModal} from "../../components/ConversationsModal/ConversationsModal.tsx";

interface ConversationType {
    id: string;
    bot?: string;
    channel: string;
    created_at: string;
}

interface CanaiaTableProps {
    dataRow: { id: string; created_at: string; channel: string }[];
    onDelete: (id: string) => void; // Prop para manejar la eliminación
}

export const CanaiaTable = ({dataRow, onDelete}: CanaiaTableProps) => {

    const [rowRecords, setRowRecords] = useState<ConversationType[]>([]);
    const {accessToken} = authStore();
    const [open, setOpen] = useState(false);
    const [openMessage, setOpenMessage] = useState(false);
    const [conversationId, setConversationId] = useState('');
    const deleteMutation = UseDeleteConversation(accessToken);

    useEffect(() => {
        if (dataRow) {
            setRowRecords(dataRow);
        }
    }, [dataRow]);

    const solved = () => {
        return "Completed"
    }

    const prepareActionDelete = (id: string) => {
        setOpen(true);
        setConversationId(id.toString());
    }

    const deleteConversation = () => {

        if (conversationId !== '') {
            deleteMutation.mutate(conversationId, {
                onSuccess: () => {
                    onDelete(conversationId);
                    setOpen(false);
                },
                onError: (error) => {
                    console.log(error)
                },
            });
        }

    }

    const openConversation = (id: string) => {
        setConversationId(id.toString());
        setOpenMessage(true);
    }

    const closeConversation = () => {
        setOpenMessage(false)
    }

    const formatTimeStamp = (created_at: string, id: string) => {
        return <div className={'conversation-button'} onClick={() => openConversation(id)}><DocumentBlank
            size={21}></DocumentBlank>{created_at.slice(0, -7)}</div>
    }

    const columns: TableColumn<ConversationType>[] = [
        {
            name: "Timestamp",
            cell: dataRow => formatTimeStamp(dataRow.created_at, dataRow.id),
            style: {
                border: '2px solid rgba(12, 86, 192, 1)',
                borderRadius: '8px',
                color: 'rgba(12, 86, 192, 1)',
                fontFamily: 'Sansation-Bold',
                cursor: 'pointer',
                padding: '0.5rem',
                margin: '0.5rem 1.2rem',
            }
        },
        {
            name: "Channel",
            selector: dataRow => dataRow.channel,
            style: {
                fontFamily: 'Sansation-Bold',
                textTransform: 'capitalize',
            },
            sortable: true,
            width: '200px'
        },
        {
            name: "Solved",
            selector: solved,
            sortable: true,
            width: '150px'
        },
        {
            name: "Actions",
            cell: dataRow => (
                <div className={'action-delete'} onClick={() => prepareActionDelete(dataRow.id)}>
                    <TrashCan size={21}></TrashCan> Delete
                </div>
            ),
            style: {
                cursor: 'pointer'
            },
            width: '150px'
        }
    ]

    return (
        <>
            <DataTable
                className={'conversation-table'}
                columns={columns}
                data={rowRecords}>
            </DataTable>
            <ConversationsModal key={conversationId} conversationId={conversationId} onClick={closeConversation} open={openMessage}></ConversationsModal>
            <Modal
                open={open}
                onRequestClose={() => setOpen(false)}
                onRequestSubmit={deleteConversation}
                danger modalHeading="Are you sure you want to delete this conversation?"
                modalLabel=""
                primaryButtonText="Delete Conversation"
                secondaryButtonText="Cancel"/>
        </>
    )
}
