import React, { useEffect, useState } from "react";
import { Assistant } from "../../../../api/ApiCalls.tsx";
import DataTable, { TableColumn } from "react-data-table-component";
import { Column, Grid, IconButton, Loading, TextInput } from "@carbon/react";
import { OverflowMenuVertical } from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";
import './ViewAssistants.scss';

interface Props {
    data: Assistant[]; // Tipo más específico
}

export const AssistantsGrid = ({ data }: Props) => {
    const [rowRecords, setRowRecords] = useState<Assistant[]>([]);
    const [pending, setPending] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (data) {
            setRowRecords(data);
        }
        setPending(false);
    }, [data]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = e.target.value.toLowerCase();
        const filteredRows = data.filter((row: Assistant) =>
            row.name.toLowerCase().includes(searchValue)
        );
        setRowRecords(filteredRows);
    };

    const handleNavigation = (pageId: number | undefined) => {
        navigate(`/global/${pageId}`);
    };

    // Definición de columnas directamente
    const columns: TableColumn<Assistant>[] = [
        {
            name: "Assistant name",
            selector: row => row.name,
            sortable: true,
        },
        {
            name: "Type",
            selector: row => row.type,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <IconButton kind={'ghost'} onClick={() => handleNavigation(row.id)} label={'Settings'}>
                    <OverflowMenuVertical />
                </IconButton>
            ),
            width: '100px',
        }
    ];

    return pending ? (
        <Loading className={'table-loading'} withOverlay={false} />
    ) : (
        <>
            <Grid fullWidth>
                <Column lg={8} md={4} sm={4}>
                    <TextInput
                        placeholder="Búsqueda"
                        onChange={handleSearch}
                        type="search"
                        id="search"
                        labelText=""
                    />
                </Column>
            </Grid>
            <DataTable
                columns={columns}
                data={rowRecords}
            />
        </>
    );
};
