import React, {useEffect, useState} from 'react';
import './MetricsViewSection.scss';
import ConversationsTable from "../ConversationsTable/ConversationsTable.tsx";
import {CanaiaDataPicker} from "../../atoms/CanaiaDataPicker/CanaiaDataPicker.tsx";
import {CanaiaLinearChart} from "../../atoms/CanaiaLineChart/CanaiaLineChart.tsx";
import {CanaiaChartLineMultiLine} from "../../atoms/CanaiaChartLineMultiLine/CanaiaChartLineMultiLine.tsx";
import {UseFetchConversationStats,UseFetchMessagesStats} from "../../../api/ApiCalls.tsx";
import {useGetAccessToken} from "../../../hooks/useGetAccessToken.tsx";
import useGetPageId from "../../../hooks/useGetPageId.tsx";
import {CanaiaFormErrorStatus} from "../../atoms/CanaiaFormErrorStatus/CanaiaFormErrorStatus.tsx";

interface MetricsViewSectionProps {}

export const MetricsViewSection: React.FC<MetricsViewSectionProps> = () => {

    const token = useGetAccessToken();
    const pageId = useGetPageId();
    // Establece la fecha actual como valor inicial
    const today = new Date();
    const formattedCurrentDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
    const [from, setFrom] = useState(formattedCurrentDate);
    const [to, setTo] = useState(formattedCurrentDate);

    // Maneja el cambio de la fecha de inicio
    const handleDateChangeFrom = (date:string) => {
        setFrom(date);//Actualiza el estado 'from' con la nueva fecha proporcionada
    }
    // Maneja el cambio de la fecha de finalización
    const handleDateChangeTo = (date:string) => {
        setTo(date);// Actualiza el estado 'to' con la nueva fecha proporcionada
    }
    // Efecto que se ejecuta cuando 'formattedCurrentDate' cambia
    useEffect(() => {
        // Actualiza la fecha de inicio con la fecha actual formateada
        handleDateChangeFrom(formattedCurrentDate)
        // Actualiza la fecha de finalización con la fecha actual formateada
        handleDateChangeTo(formattedCurrentDate);
    }, [formattedCurrentDate]);// Dependencia: el efecto se ejecuta cada vez que 'formattedCurrentDate' cambia

    const data = UseFetchMessagesStats(token, pageId, from, to );
    const totalCount = data.data as Messages | undefined;

    const data1 = UseFetchConversationStats(token, pageId, from, to);
    const totalByChanel = data1.data as Messages | undefined;
    console.log(totalByChanel)

    /*console.log(data1.data);
    console.log(data.data);*/
    /*const existingData = {
        "2024-10-01": {
            "count": {
                "telegram": 3,
                "whatsapp": 8,
                "phone": 8,
                "web": 4
            }
        },
        "2024-10-02": {
            "count": {
                "telegram": 3,
                "whatsapp": 10,
                "phone": 8,
                "web": 4
            }
        },
        "2024-10-03": {
            "count": {
                "telegram": 3,
                "whatsapp": 12,
                "phone": 8,
                "web": 4
            }
        },
        "2024-10-04": {
            "count": {
                "telegram": 3,
                "whatsApp": 12,
                "phone": 8,
                "web": 4
            }
        },
        "2024-10-05": {
            "count": {
                "telegram": 6,
                "whatsapp": 12,
                "phone": 8,
                "web": 4
            }
        },
        "2024-10-06": {
            "count": {
                "telegram": 1,
                "whatsapp": 12,
                "phone": 8,
                "web": 4
            }
        },
        "2024-10-07": {
            "count": {
                "telegram": 10,
                "whatsapp": 12,
                "phone": 8,
                "web": 4
            }
        },
        "2024-10-08": {
            "count": {
                "telegram": 10,
                "whatsapp": 12,
                "phone": 8,
                "web": 4
            }
        },
        "2024-10-09": {
            "count": {
                "telegram": 10,
                "whatsApp": 12,
                "phone": 8,
                "web": 4
            }
        },
        "2024-10-10": {
            "count": {
                "telegram": 10,
                "whatsapp": 12,
                "phone": 8,
                "web": 4
            }
        },
        "2024-10-11": {
            "count": {
                "telegram": 10,
                "whatsapp": 12,
                "phone": 8,
                "web": 4
            }
        }
    };*/

    //Define la estructura de los datos existentes
    interface Messages {
        // Clave de tipo string que representa una fecha
        [date: string]: {
            // Objeto que contiene el conteo de interacciones por canal
            count: {
                // Clave de tipo string que representa el nombre del canal
                [channel: string]: number; // Valor de tipo number que representa la cantidad de interacciones
            };
        };
    }
    // Transforma los datos existentes a un formato adecuado
    const transformData = (data: Messages|undefined)=>{
        if (!data) return [];

        return Object.entries(data).map(([date, {count}])=>{
            // Suma todos los valores de los canales
            const total =  Object.values(count).reduce((acc,value) => acc + value, 0);
            const formattedDate = `${date}T00:00:00`; // Formato de fecha ISO
            return { date: formattedDate, value: total };
        })
    }
    // Transforma los datos existentes a un formato adecuado
    function transformDataByChannels(data: Messages|undefined): { group: string; date: string; value: number; }[]{
        if (!data) return [];
        // Inicializa un array vacío para almacenar los resultados transformados
        const result: { group: string; date: string; value: number; }[] = [];

        // Mapeo de nombres de canales a sus versiones capitalizadas
        const channelMapping: { [key: string]: string } = {
            telegram: 'Telegram',
            whatsapp: 'WhatsApp',
            phone: 'Phone',
            web: 'Web',
        };

        // Itera sobre las fechas y canales para construir el resultado
        Object.keys(data).forEach((date) => {

            // Para cada fecha, itera sobre los canales disponibles en el objeto count
            Object.keys(data[date].count).forEach((channel) => {
                // Obtiene el nombre capitalizado del canal usando el mapeo definido anteriormente
                const groupName = channelMapping[channel];
                // Agrega un nuevo objeto al resultado que contiene el grupo, la fecha y el valor correspondiente
                result.push({
                    group: groupName, // Nombre del canal capitalizado
                    date: `${date}T00:00:00.000`, // Fecha del registro
                    value: data[date].count[channel], // Valor asociado a ese canal y fecha
                });
            });
        });
        // Devuelve el array con los datos transformados
        return result;
    }

    const chartData = transformData(totalCount);
    const chartDataByChannel = transformDataByChannels(totalByChanel);

    // Verifica si hay datos para mostrar
    const dataValidate = chartData.length > 0 && chartDataByChannel.length > 0;

    return (
        <div className="metrics-view-section-wrapper">
            <div className="datapickers-wrapper">
                <CanaiaDataPicker text={'Select date range from '} onDateChange={handleDateChangeFrom} pickerId={'from'} date={from}/>
                <CanaiaDataPicker text={'Select date range to '} onDateChange={handleDateChangeTo} pickerId={'to'} date={to}/>
            </div>
            {dataValidate ? (
                <>
                    <CanaiaLinearChart data={chartData}/>
                    <CanaiaChartLineMultiLine data={chartDataByChannel}/>

                </>
            ): (
                <div className="no-data-message">
                    <CanaiaFormErrorStatus text={'No hay estadísticas por mostrar.'}/>
                </div>
            )}
            <ConversationsTable/>
        </div>
    );
};