import {Select, SelectItem} from "@carbon/react";
import React from "react";

interface Props {
    id: string;
    options: {
        [key: string]: string;
    };
    onChange?: (value: string) => void;
    value: string;
    labelText: string;
}

export const CanaiaSelect = ({ options, onChange, value, id, labelText}: Props) => {

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        if (onChange) {
            onChange(selectedValue);  // Llama a la función onChange si está definida
        }
    };

    return (
        <div className={"canaia-select"}>
            <Select
                id={id}
                labelText={labelText}
                helperText=""
                onChange={handleSelectChange}
                value={value}
            >
                {Object.entries(options).map(([key, value]) => (
                    <SelectItem key={key} value={value} text={key.replace(/_/g, " ")}/>
                ))}
            </Select>
        </div>
    );
};
