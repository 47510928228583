import {Column, Grid} from "@carbon/react";
import {AssistantCard} from "../../../../ui/components/AssistantCard/AssistantCard.tsx";
import {MainLayout} from "../../../../ui/layout/MainLayout.tsx";
import {useNavigate} from "react-router-dom";


export const CatalogueAssistants = () => {

    const navigate = useNavigate();

    const onNavigate = () => {
        navigate('/new-assistant')
    }

    return (
        <MainLayout>
            <Grid>
                <Column span={16}>
                    <h2 className={'grey-color-text assist-h2'}>
                        What kind of <span className={'secondary-color'}>assistant</span> would you like to create?
                    </h2>
                </Column>
            </Grid>
            <Grid narrow={true} className={"assist-grid"}>
                <Column lg={4} md={4} sm={4}>
                    <AssistantCard
                        iconName={"DocumentBlank"}
                        type={"blank"}
                        description={"Create a new assistant."}
                        onClick={onNavigate}>
                    </AssistantCard>
                </Column>
                <Column lg={4} md={4} sm={4}>
                    <AssistantCard
                        iconName={"Catalog"}
                        type={"booking"}
                        description={"Make reservations for your business."}
                        onClick={() => console.log('Booking')}>
                    </AssistantCard>
                </Column>
                <Column lg={4} md={4} sm={4}>
                    <AssistantCard
                        iconName={"Restaurant"}
                        type={"Restaurant"}
                        description={"Make reservations for your restaurant and answer questions about your menu."}
                        onClick={() => console.log('Restaurante')}>
                    </AssistantCard>
                </Column>
                <Column lg={4} md={4} sm={4}>
                    <AssistantCard
                        iconName={"CopyFile"}
                        type={"Hotel"}
                        description={"Make reservations for your hotel or meets the needs of your guests. "}
                        onClick={() => console.log('Hotel')}>
                    </AssistantCard>
                </Column>
            </Grid>
        </MainLayout>
    )
}

export default CatalogueAssistants