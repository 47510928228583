import React, { useState } from 'react';
import { LineChart, ScaleTypes } from "@carbon/charts-react";
import './CanaiaChartLineMultiLine.scss';
import '@carbon/charts-react/styles.css';
import IconPhone from '../../../assets/images/phone.svg';
import IconTelegram from '../../../assets/images/telegram.svg';
import IconWhatsApp from '../../../assets/images/whatsapp.svg';
import IconWeb from '../../../assets/images/web.svg';



interface CanaiaChartLineMultiLineProps {
    data: { group: string; date: string; value: number }[];
}

const legends = {
    'Phone': {
        icon: IconPhone, // Reemplaza con la URL de tu icono
        label: 'Phone',
    },
    'Telegram': {
        icon: IconTelegram, // Reemplaza con la URL de tu icono
        label: 'Telegram',
    },
    'WhatsApp': {
        icon: IconWhatsApp, // Reemplaza con la URL de tu icono
        label: 'WhatsApp',
    },
    'Web': {
        icon: IconWeb, // Reemplaza con la URL de tu icono
        label: 'Web',
    },
};


export const CanaiaChartLineMultiLine: React.FC<CanaiaChartLineMultiLineProps> = ({ data }) => {
    // Inicializa los grupos visibles como todas las claves de las leyendas
    const initialVisibleGroups = Object.keys(legends);
    // Estado para controlar qué grupos son visibles en el gráfico
    const [visibleGroups, setVisibleGroups] = useState(initialVisibleGroups);

    // Función para alternar la visibilidad de los grupos
    const toggleGroupVisibility = (group: string) => {
        // Evita que el último grupo activo sea desactivado
        setVisibleGroups((prev) => {
            if (prev.length === 1 && prev.includes(group)) {
                return prev;
            }
            // Cambia la visibilidad del grupo
            return prev.includes(group)
                ? prev.filter(g => g !== group) // Si ya está visible, lo oculta
                : [...prev, group]; // Si no está visible, lo muestra
        });
    };

    // Filtra los datos para mostrar solo los grupos visibles
    const filteredData = data.filter(item => visibleGroups.includes(item.group));

    // Define las series para el gráfico, separando los datos por grupo
    const series = [
        {
            group: 'Phone',
            data: filteredData.filter(item => item.group === 'Phone'), // Datos para cada grupo
        },
        {
            group: 'Telegram',
            data: filteredData.filter(item => item.group === 'Telegram'),
        },
        {
            group: 'WhatsApp',
            data: filteredData.filter(item => item.group === 'WhatsApp'),
        },
        {
            group: 'Web',
            data:filteredData.filter(item => item.group === 'Web'),
        },
    ];

    // Configuración del gráfico
    const options = {
        axes: {
            bottom: {
                title: 'Date',
                mapsTo: 'date', // Mapea a la propiedad 'date' de los datos
                scaleType: ScaleTypes.TIME,
            },
            left: {
                title: 'Conversations',
                mapsTo: 'value', // Mapea a la propiedad 'value' de los datos
                scaleType: ScaleTypes.LINEAR,
            },
        },
        height: '400px',
        color: {
            scale: {
                'Phone': '#2c899e', // Color para cada grupo
                'Telegram': '#29a9ea',
                'WhatsApp': '#25d366',
                'Web': '#920985',
            },
        },
        series: series, // Asigna las series al gráfico
    };

    return (
        <div className="canaia-chart-multiline-wrapper">
            <div className="canaia-title-wrapper">
                <div className="chart-title">Channels</div>
                <div className="custom-legend">
                    {Object.entries(legends).map(([key, {icon,label}]) => ( // Itera sobre las leyendas
                        <div
                            key={key}
                            className="legend-item"
                            onClick={() => toggleGroupVisibility(key)} // Cambia la visibilidad al hacer click
                            style={{
                                cursor: 'pointer',
                                opacity: visibleGroups.includes(key) ? 1 : 0.5
                            }} // Estilo para el cursor y opacidad
                        >
                            <img src={icon} alt={label}/>
                            <span>{label}</span> {/* Usa la etiqueta aquí */}
                        </div>
                    ))}
                </div>
            </div>
            <LineChart data={filteredData} options={options}/>
        </div>
    );
};
