import React from "react";
import {Button, ButtonSet} from "@carbon/react";
import './CanaiaSetOfButtons.scss';

interface CanaiaSetOfButtonsProps {
    primaryTitle: string;
    textPrimaryButton: string;
    secondaryTitle: string;
    textSecondaryButton: string;
    onButtonClick: (text:string) => void;
}

export const CanaiaSetOfButtons: React.FC<CanaiaSetOfButtonsProps> = ({
    primaryTitle,
    textPrimaryButton,
    secondaryTitle,
    textSecondaryButton,
    onButtonClick,
}) => {
    return (
        <div className="canaia-set-of-buttons-wrapper">
            <ButtonSet>
                <Button onClick={()=> {onButtonClick(textPrimaryButton);}}>
                    {primaryTitle}
                </Button>
                <Button onClick={()=>{onButtonClick(textSecondaryButton)}}>
                    {secondaryTitle}
                </Button>
            </ButtonSet>
        </div>
    );
};