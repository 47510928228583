import React, { useState } from "react";
import './CanaiaLateralMenu.scss';
import {CanaiaOptionMenuIcon} from "../../atoms/CanaiaOptionMenuIcon/CaniaOptionMenuIcon.tsx";
import IconMetrics from '../../../assets/images/option-metrics.svg';
import IconEvents from '../../../assets/images/option-events.svg';
import IconAssistant from '../../../assets/images/option-assistant.svg';
import IconPlayground from '../../../assets/images/option-playground.svg'
import IconChannels from '../../../assets/images/option-channels.svg';
import IconIntegrations from '../../../assets/images/option-intregrations.svg';
import IconVersions from '../../../assets/images/option-version.svg';
import IconSettings from '../../../assets/images/option-settings.svg';


// Definimos las opciones posibles para el menú lateral
export type MenuOption = "Metrics" | "Events" | "Assistant" | "Playground" | "Channels" | "Integrations" | "Versions" | "Settings";

// Array que contiene todas las opciones del menú lateral y su icono
const optionsMenu = [
    { icon: IconMetrics, text: 'Metrics' },
    { icon: IconEvents, text: 'Events' },
    { icon: IconAssistant, text: 'Assistant' },
    { icon: IconPlayground, text: 'Playground' },
    { icon: IconChannels, text: 'Channels' },
    { icon: IconIntegrations, text: 'Integrations' },
    { icon: IconVersions, text: 'Versions' },
    { icon: IconSettings, text: 'Settings' },
];

// El prop 'onSelect' es una función que espera recibir una opción del tipo 'MenuOption' cuando se selecciona una opción del menú.
interface  CanaiaLateralMenuProps {
    onSelect:(option: MenuOption) => void;
}
export const CanaiaLateralMenu: React.FC<CanaiaLateralMenuProps> = ({onSelect}) => {
    // Estado local 'activeOption'  inicialmente se establece en 'Metrics'
    const [activeOption, setActiveOption] = useState<MenuOption>("Metrics");

    // Función que se ejecuta cuando el usuario hace clic en una opción del menú
    const handleClick = (text: MenuOption) => {
        onSelect(text); // Actualiza la opción activa
        setActiveOption(text); // Envia la opción seleccionada al componente padre a través de 'onSelect'
        //console.log(`Opción seleccionada: ${text}`);
    }

    return (
        <div className="canaia-lateral-menu">
            {optionsMenu.map((option, index) => (
                <CanaiaOptionMenuIcon
                    text={option.text}
                    key={index}
                    image={option.icon}
                    onClick={()=>handleClick(option.text as MenuOption)}
                    isSelected={ activeOption === option.text }
                />
            ))}
        </div>
    )
};
