import {
    Header,
    HeaderGlobalBar,
    HeaderMenu,
    HeaderMenuItem,
    HeaderName,
    HeaderNavigation
} from "@carbon/react";
import CanaiaLogo from "../../atoms/CanaiaLogo/CanaiaLogo.tsx";
import {CanaiaButton} from "../../atoms/CanaiaButton/CanaiaButton.tsx";
import './AssistantHeader.scss'
import {useLogto} from "@logto/react";
import {Add, User} from '@carbon/icons-react';
import {useNavigate} from "react-router-dom";
import {authStore} from "../../../store/authStore.tsx";

interface Props {
    page?: string;
}

export const AssistantHeader = ({page}:Props) => {

    const {signOut} = useLogto();
    const {userName} = authStore.getState();
    const navigate = useNavigate();

    return (
        <Header className={`canaia-header ${page}`} aria-label={"CanaiaAssist"}>
            <HeaderName
                className={'header-name'}
                prefix={''} isSideNavExpanded={false} as={'a'}
                onClick={() => {navigate('/');}}>
                <CanaiaLogo className={"logo-white"} type={"logo-white"}></CanaiaLogo>
                <CanaiaLogo className={"logo-color"} type={"logo-color"}></CanaiaLogo>
            </HeaderName>
            <HeaderGlobalBar>
                <CanaiaButton onClick={() => {navigate('/new-assistant');}} kind={"secondary"} width={"175px"}>
                    <Add className={"white-color"}></Add>New Assistant
                </CanaiaButton>
                <HeaderNavigation aria-label="IBM [Platform]">
                    <div className={"cds--header__submenu-icon"}>
                        <User></User>
                    </div>
                    <HeaderMenu aria-label="Link 4" menuLinkName={userName}>
                        <HeaderMenuItem
                            className={'cds--header__submenu-link'}>
                            Settings
                        </HeaderMenuItem>
                        <HeaderMenuItem
                            onClick={() => signOut(window.location.origin + "/")}
                            className={'cds--header__submenu-link'}>
                            Logout
                        </HeaderMenuItem>
                    </HeaderMenu>
                </HeaderNavigation>
            </HeaderGlobalBar>
            {/*<SideNav
                aria-label="Side navigation"
                expanded={true}
                isPersistent={false}
            >
                <SideNavItems>
                    <SideNavLink >
                        One
                    </SideNavLink>
                    <SideNavLink >
                        Two
                    </SideNavLink>
                    <SideNavLink >
                        Three
                    </SideNavLink>
                </SideNavItems>
            </SideNav>*/}
        </Header>
    )
}
