import './CanaiaSlider.scss';
import {Slider} from "@carbon/react";
import {useEffect, useState} from "react";

interface CanaiaSliderProps {
    ariaLabelInput: string;
    disabled?: boolean;
    invalidText: string;
    labelText: string;
    max: number;
    min: number;
    step: number;
    stepMultiplier: number;
    unstable_ariaLabelInputUpper: string;
    value: number;
    warnText: string;
    onChangeValue: (value: number) => void;
}

export const CanaiaSlider: React.FC<CanaiaSliderProps> = ({
    ariaLabelInput,
    disabled = false,
    invalidText,
    labelText,
    max,
    min,
    step,
    stepMultiplier,
    unstable_ariaLabelInputUpper,
    value,
    warnText,
    onChangeValue,
}) => {

    const [inputValue, setValue] = useState(value);
    // Actualiza inputValue cuando value cambia
    useEffect(() => {
        setValue(value);
    }, [value]);
    const getSliderValue = (value: number) => {
        setValue(value);
        onChangeValue(value);
    }

    return (
        <div className={'canaia-wrapper'}>
            <div className={'canaia-slider__tags'}>
                <span className={inputValue > 0.5 ? 'no-active' : ''}>Formal</span>
                <span className={inputValue < 0.5 ? 'no-active' : ''}>Creative</span>
            </div>
            <Slider
                className="canaia-slider"
                ariaLabelInput={ariaLabelInput}
                invalidText={invalidText}
                disabled={disabled}
                labelText={labelText}
                max={max}
                min={min}
                onChange={(e) => getSliderValue(e.value)}
                step={step}
                stepMultiplier={stepMultiplier}
                unstable_ariaLabelInputUpper={unstable_ariaLabelInputUpper}
                value={inputValue}
                hideTextInput={true}
                warnText={warnText}
            >
                {/* Proporciona un prop children vacío */}
                <></>
            </Slider>
        </div>
    )
}