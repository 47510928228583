import {Button} from "@carbon/react";
import {ButtonKind, ButtonSize} from "@carbon/react/lib/components/Button/Button";
import React from "react";
import './CanaiaButton.scss';

interface CanaiaButtonProps {
    buttonSize?: ButtonSize;
    children: React.ReactNode;
    href?: string;
    kind: ButtonKind;
    noRadius?: boolean,
    onClick?: () => void,
    disabled?: boolean,
    width?: string,
}

export const CanaiaButton: React.FC<CanaiaButtonProps> = ({
    buttonSize,
    children,
    kind,
    onClick,
    noRadius,
    disabled = false,
    width = '200px',

}) => {

    return (
        <Button
            kind={kind}
            size={buttonSize}
            onClick={onClick}
            disabled={disabled}
            className={ ` canaia-button  ${noRadius ? 'with-no-radius' : ''}` }
            style={{ maxWidth: width }}
        >
            {children}
        </Button>
    )
}
