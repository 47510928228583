import React, {useState} from "react";
import './AssistantViewSection.scss';
import {CanaiaTitleSimple} from "../../atoms/CanaiaTitleSimple/CanaiaTitleSimple.tsx";
import {CanaiaButtonWithIcon} from "../../atoms/CanaiaButtonWithIcon/CanaiaButtonWithIcon.tsx";
import {CanaiaSettingsPhoneChannelSection} from "../CanaiaSettingsPhoneChannelSection/CanaiaSettingsPhoneChannelSection.tsx";
import {CanaiaSettingsTelegramChannelSection} from "../CanaiaSettingsTelegramChannelSection/CanaiaSettingsTelegramChannelSection.tsx";
import {CanaiaSettingsWhatsAppChannelSection} from "../CanaiaSettingsWhatsAppChannelSection/CanaiaSettingsWhatsAppChannelSection.tsx";
import {CanaiaSettingsWebChannelSection} from "../CanaiaSettingsWebChannelSection/CanaiaSettingsWebChannelSection.tsx";
//import iconos de botones
import IconPhone from '../../../assets/images/phone.svg';
import IconTelegram from '../../../assets/images/telegram.svg';
import IconWhatsapp from '../../../assets/images/whatsapp.svg';
import IconWeb from '../../../assets/images/web.svg';

type Channel = "Phone" | "Telegram" | "WhatsApp" | "Web";

interface AssistantViewSectionProps {}

export const AssistantViewSection: React.FC<AssistantViewSectionProps> = ()=>{

    const titleChannels = {Phone: IconPhone, Telegram: IconTelegram,WhatsApp: IconWhatsapp, Web: IconWeb};


    //Estado para la opción activa
    const [activeChannel, setActiveChannel] = useState<Channel>('Phone');
    const  handleChannelClick = (chanel:Channel)=> {
        setActiveChannel(chanel)
        console.log(`Seleccionaste: ${chanel}`);
    }
    // Mapeo de contenido  por canal
    const channelContent: Record<Channel,JSX.Element>={
        Phone: <CanaiaSettingsPhoneChannelSection/>,
        Telegram: <CanaiaSettingsTelegramChannelSection/>,
        WhatsApp: <CanaiaSettingsWhatsAppChannelSection/>,
        Web: <CanaiaSettingsWebChannelSection/>,
    }
    return (
        <div className="assistant-view-section-wrapper">
            <CanaiaTitleSimple title={'channels'}/>
            <div className="channels-options-wrapper">
                {
                    Object.entries(titleChannels).map(([title, image]) => (
                        <CanaiaButtonWithIcon
                            key={title}
                            title={title}
                            image={image}
                            onClick={()=>handleChannelClick(title as Channel)}
                            className={activeChannel === title ? "active" : ""}
                        />
                    ))
                }
            </div>
            <div className="channel-content-wrapper">
                {channelContent[activeChannel]}
            </div>
        </div>
    )
}