import React from "react";
import './IntegrationsViewSection.scss';

interface IntegrationsViewSectionProps {}

export const IntegrationsViewSection: React.FC<IntegrationsViewSectionProps> = ()=> {
    return (
        <div className="integrations-view-section-wrapper">
            Sección Integrations
        </div>
    )
}