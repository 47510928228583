import {Modal} from "@carbon/react";
import './CanaiaDangerModal.scss';
import React from "react";

interface ModalPros {
    children: React.ReactNode;
    open: boolean;
    onClose: () => void;
    onPrimaryButtonClick?: () => void; // Prop para manejar el clic en el botón primario
    onSecondaryButtonClick?: () => void; // Prop para manejar el clic en el botón secundario
}

export const CanaiaDangerModal: React.FC<ModalPros> = ({
    children,
    open,
    onClose,
    onPrimaryButtonClick,
}) => {

    return <>
        <Modal
            className={"canaia-modal"}
            open={open}
            onRequestClose={onClose}
            danger={true}
            onRequestSubmit={onPrimaryButtonClick} // Llama a la función al hacer clic en el botón primario
            onSecondarySubmit={onClose}
            primaryButtonText="Delete" secondaryButtonText="Cancel">
            {children}
        </Modal>
    </>;
}

export default CanaiaDangerModal;
