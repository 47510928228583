//
//
//  Home
//
//

import {InlineLoading} from "@carbon/react";
import {useNavigate} from "react-router-dom";
import {useLogto} from "@logto/react";
import {useEffect} from "react";
import {Navigate} from "react-router-dom";


export const Home = () => {

    const {isAuthenticated, isLoading, signIn} = useLogto();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            if (!isAuthenticated && !isLoading) {
                signIn(window.location.origin + '/sign-in-callback').then(resp => resp);
            }
        })();
    }, [isAuthenticated, isLoading, navigate, signIn]);

    if (isLoading) {
        return <InlineLoading status="active" iconDescription="Loading" description="Redirecting..."/>
    }

    if (!isLoading && isAuthenticated) {
        return <Navigate to={'/assistants'} replace={true}/>
    }

    return null
}

export default Home
