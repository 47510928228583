import React from "react";
import {Add, DocumentBlank} from "@carbon/icons-react";
import './AssistantCard.scss'

interface AssistantCardProps {
    description: string,
    iconName: keyof typeof assistantTypes,
    onClick: () => void
    type: string,
}

const assistantTypes = {
    'Add': {
        IconTag: 'Add',
    },
    'Catalog': {
        IconTag: 'Catalog',
    },
    'CopyFile': {
        IconTag: 'CopyFile',
    },
    'DocumentBlank': {
        IconTag: 'DocumentBlank',
    },
    'Restaurant': {
        IconTag: 'Restaurant'
    }
}

export const AssistantCard: React.FC<AssistantCardProps> = ({
    description,
    type,
    onClick
}) => {
    return (
        <div role={"button"} className={"card-button"} onClick={onClick}>
            <div>
                <div className="icon icon__card">
                    <DocumentBlank className={"icon-primary-color"} size={48}></DocumentBlank>
                </div>
                <h2>{type}</h2>
                <p>{description}</p>
                <div className={"icon icon__add"}>
                    <Add className={"icon-primary-color"} size={48}></Add>
                </div>
            </div>
        </div>
    )
}